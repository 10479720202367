.rsvpLink {
    height: 100;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
}

.innerRsvpLink {
    background-color: #FFFFFF;
    border-radius: 45;
    box-shadow: 0px 0px 25px 25px rgb(255, 255, 255);
    animation: fadein-after 10s;
    padding: 10px
}