@import url('https://fonts.googleapis.com/css?family=Great+Vibes');
@import url('https://fonts.googleapis.com/css?family=Old+Standard+TT');
@import url('https://fonts.googleapis.com/css?family=Cinzel');
@import url('https://fonts.googleapis.com/css?family=Quintessential');

.App {
  height: 100dvh;
  width: 100dvw;
  text-align: center;
  background-color: #FFFFFF;
  font-family: 'Old Standard TT';
  color: #282c34;
  font-size: 14px;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.section {
  padding: 2vw;
  display: flex;
  flex-direction: row
}

.container {
  flex: 1;
  padding: 1vw;
}

.chakra-form-control {
  padding-bottom: 20px;
  z-index:0;
}

.phoneInput {
  z-index:1
}

.react-international-phone-country-selector .chakra-button {
  border-color: transparent;
}

@media screen and (max-width: 1000px) {
  .section {
    flex-direction: column;
  }
}

@keyframes fadein {
  0% { opacity:0; }
  100% { opacity:1; }
}

@keyframes fadein-after {
  0% { opacity:0; }
  66% { opacity:0; }
  100% { opacity:1; }
}

.fade-in-section {
  opacity: 0;
  transform: translateX(20vw);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
  height: 0
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
  height: auto;
}

h1, h2 {
  font-family: 'Cinzel', cursive;
  color: #1F65A5;
  font-size: 36px;
  font-weight: normal;
  padding-bottom: 16px;
}

h3 {
  font-family: 'Cinzel', cursive;
  font-size: 24px;
  color: #454545
}

p {
  text-align: left;
  padding-bottom: 16px;
}
