.header {
    height: 100dvh;
    width: 100dvw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    animation: fadein 2s;
}

.innerHeader {
    background-color: #FFFFFF;
    width: 70vw;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 45;
    box-shadow: 0px 0px 25px 25px rgb(255, 255, 255);
    animation: fadein-after 4s;
}

@keyframes fadein {
    0% { opacity:0; }
    100% { opacity:1; }
}

@keyframes fadein-after {
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}

.title {
    font-family: 'Cinzel', cursive;
    font-size: 72px;
    color: #1F65A5
}

.sub-title {
    font-family: 'Cinzel';
    font-size: 36px;
    color: #454545
}

@media screen and (max-width: 1000px) {
    .title{
        font-size: 36px;
    }
    .sub-title{
        font-size: 24px;
    }
}