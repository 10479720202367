.ImageContainer {
    width: 100%;
    height: 100%;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Image {
    max-width: 60%;
    border-radius: 10px
}